import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ThemeContext } from '../context/themeContext';

import Nav from './Nav'

const ContentStyles = styled.div`
	padding-left: 1.67em;
  padding-top: 2em;
  margin-top: 2em;
	@media (max-width: 375px) {
    padding: 0;
    padding-left: .78em;
    padding-top: .33em;
  }
`;

export default function Layout({ children }) {
  const { theme } = useContext(ThemeContext);
  return (
    <>
      <div className={`${theme === 'light' ? 'theme-light' : 'theme-dark'} bg-primary text-main-text transition-all duration-300 m-0 px-0 py-5 min-h-screen`}>
        <ContentStyles>
          <Nav />
          {children}
        </ContentStyles>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};