import React, { useContext } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Moon from "../assets/moon.svg"
import Sun from "../assets/sun.svg"


import Toggle from 'react-toggle';
import { ThemeContext } from '../context/themeContext';

const NavStyles = styled.nav`
	ul {
  	display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
    margin-left: 2%;
    margin-right: 13%;
    /* No greater than 374px, no less than 280px */
    @media (max-width:374px) and (min-width:280px) {
      margin-right: 2%;
    }​

    @media (max-width: 375px) {
      max-width: 335px;
      margin-right: 0;
      margin-left: 3%;
    }  
	}
`;

const ToggleStyles = styled.li`
  justify-content: space-end;
  text-align: right;
`;

export default function Nav(props) {
  const { theme, setTheme } = useContext(ThemeContext);

  const handleThemeToggle = () => {
    if (theme === 'light') {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  };

  return (
    <NavStyles>
      <ul>
        <li className="nav-item-logo">
          <Link to="/" className="navLink">Matt Oliver</Link>
        </li>
        <li className="nav-item">
          <span className="navLink">/</span>
        </li>

        <li className="nav-item">
          <Link to="/info/" className="navLink">Info</Link>
        </li>

        <ToggleStyles>
          <Toggle
            id="theme-toggle"
            checked={theme === 'light' ? true : false}
            onChange={handleThemeToggle}
            icons={{
              checked: <Moon color="#FFF" />,
              unchecked: <Sun />
            }}
            aria-labelledby="theme-toggle"
            aria-label="theme-toggle"
          />
        </ToggleStyles>
      </ul>
    </NavStyles>
  )

}