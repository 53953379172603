import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';


export default function SEOComponent({ children, location, description, title, image }) {
	// useStaticQuery for querying data into a component when not at a page level
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          twitter
        }
      }
    }
  `);
	return (	
		<Helmet titleTemplate={`%s - ${site.siteMetadata.title}`}>
			<html lang='en'/>
			<title>{title}</title>
		{/* Favicons */}
			<link rel='icon' type='image/svg+xml' href='/favicon.svg' />
			<link rel='alternate icon' href='/favicon.ico' />
			<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
			<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
			<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
			<meta name="msapplication-TileColor" content="#da532c" />
			<meta name="theme-color" content="#ffffff" />
		{/* Meta tags */}
			<meta name='viewport' content='width=device-width, initial-scale=1.0'/>
			<meta charSet='utf-8' />
			<meta name='description' content={site.siteMetadata.description} />
		{/* Open graph & Twitter */}
		<meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="http://theemattoliver.com" />
    <meta name="twitter:title" content="Musician, artist and engineer Matt Oliver." />
    <meta name="twitter:description" content="Musician, artist and engineer Matt Oliver." />
    <meta name="twitter:image" content="http://theemattoliver.com/assets/images/matt-guitar.jpg" />

		{location && <meta property='og:url' content={location.href} />}
		<meta property='og:image' content={image || '/logo.svg'} />
		<meta property='og:title' content={title} key='ogtitle' />
		<meta property='og:site_name' content={site.siteMetadata.title} key='ogsitename'/>
		<meta property='og:description' content={description} key='ogdescription' />
		{children}

		</Helmet>
	);
}

